/* You can add global styles to this file, and also import other style files */
@import '@angular/cdk/overlay-prebuilt.css';
@import 'app/shared/styles/modal';
@import 'app/shared/styles/popover';
@import 'app/shared/styles/reset';
@import 'app/shared/styles/spinner';
@import 'app/shared/styles/table';
@import 'app/shared/styles/tippy';

html,
body {
  height: 100%;
}

body {
  margin: 0;
}

*,
::before,
::after {
  box-sizing: border-box;
}

.hidden {
  display: none;
}
