ul,
menu {
  list-style-type: none;
  margin: 0;
  padding: 0;

  > li {
    display: block;
  }
}
