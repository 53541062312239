.loading-spinner {
  position: relative;
  overflow: hidden; // Fix for PDS bug where spinner overflows, WARN: PDS modification
}

.overlay-spinner-host {
  position: relative;
}

.overlay-spinner {
  position: absolute;
  overflow: hidden; // Fix for PDS bug where spinner overflows, WARN: PDS modification
  inset: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;

  &::before {
    content: '';
    display: block;
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    background: var(--theme-background-base);
    opacity: 0.75;
  }
}
