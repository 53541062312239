@use '@porsche-design-system/components-angular/styles' as *;
@import 'tippy.js/dist/tippy.css';

[data-tippy-root] {
  .tippy-box {
    background: var(--theme-background-base);
    filter: drop-shadow(rgb(0 0 0 / 30%) 0 0 16px); // WARN! Copied from PDS
    backdrop-filter: drop-shadow(transparent 0 0 0); // WARN! Copied from PDS
    border-radius: $pds-spacing-static-x-small;
    animation: 0.25s cubic-bezier(0.25, 0.1, 0.25, 1) 0s 1 normal forwards running keyframes-fadeIn !important; // WARN! Copied from PDS

    /* stylelint-disable-next-line keyframes-name-pattern */
    @keyframes keyframes-fadeIn {
      0% {
        opacity: 0;
      }

      100% {
        opacity: 1;
      }
    }

    .tippy-content {
      color: var(--theme-primary);
      font-size: $pds-font-size-text-small;
      padding: $pds-spacing-static-small $pds-spacing-static-medium;
      overflow-wrap: break-word;
      hyphens: auto;
      overflow: auto;
    }

    .tippy-arrow {
      color: var(--theme-background-base);

      &::before {
        transform: scale(1.5);
      }
    }

    &[data-theme='theme-dark'] {
      background: var(--theme-background-surface);

      .tippy-arrow {
        color: var(--theme-background-surface);
      }
    }
  }
}
