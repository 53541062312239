@use '@porsche-design-system/components-angular/styles' as *;

.popover-content {
  max-height: 80vh; // Do not use dvh because popover position is calculated only when it opens and dvh would break it. This also means high values like >90vh might be cut off
  overflow: auto;
  padding: $pds-spacing-static-small $pds-spacing-static-medium; // Copied from PDS popover, WARN: PDS modification
  margin: (-$pds-spacing-static-small) (-$pds-spacing-static-medium); // Position over PDS popover padding, WARN: PDS modification

  > p-text {
    overflow: hidden; // Fix for PDS bug where p-text overflows, WARN: PDS modification
  }
}
