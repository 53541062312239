p-table {
  p-table-head-cell,
  p-table-cell {
    &.align-end {
      text-align: end;
    }
  }

  .empty-cell {
    &::after {
      content: '\00a0'; // Non-breaking space
    }
  }
}
